import { Product, WithContext } from "schema-dts";
import { SchemaOutput } from "../types";

type BaseProduct = WithContext<Product>;
type TeliaProduct = Pick<
  BaseProduct,
  | "@id"
  | "@type"
  | "name"
  | "alternateName"
  | "description"
  | "image"
  | "category"
  | "productID"
  | "url"
  | "brand"
  | "offers"
>;
export type TeliaProductAttributes = Omit<TeliaProduct, "@type">;

const schemaBlob = <T>(obj: T): BaseProduct => {
  return {
    "@context": "https://schema.org",
    "@type": "Product",
    ...obj,
  };
};

export const productMeta = (obj: TeliaProductAttributes): SchemaOutput => {
  const blob = schemaBlob<TeliaProductAttributes>(obj);
  return {
    json: () => JSON.stringify(blob),
  };
};
