type PropertyMetaFields = {
  ogSiteName?: string | null;
  ogTitle?: string | null;
  ogDescription?: string | null;
  ogType?: string | null;
  ogImage?: string | null;
  ogImageAlt?: string | null;
  ogUrl?: string | null;
  ogLocale?: string | null;
  articleSection?: string | null;
  articleTag?: string[] | null;
  articlePublishedTime?: string | null;
  articleModifiedTime?: string | null;
  articleExpirationTime?: string | null;
};

export type MetaFields = {
  description: string;
  keywords?: string | null;
  robots?: string | null;
  canonical?: string | null;
  author?: string | null;
} & PropertyMetaFields;

type PropertyKeys = keyof PropertyMetaFields;

export type MetaOptions = {
  hiddenFromSearchEngine?: boolean | null;
  excludeLinksFromSearchRankings?: boolean | null;
};

const transformedTagNames: Record<PropertyKeys, string> = {
  ogSiteName: "og:site_name",
  ogTitle: "og:title",
  ogDescription: "og:description",
  ogType: "og:type",
  ogImage: "og:image",
  ogImageAlt: "og:image:alt",
  ogUrl: "og:url",
  ogLocale: "og:locale",
  articleSection: "article:section",
  articleTag: "article:tag",
  articlePublishedTime: "article:published_time",
  articleModifiedTime: "article:modified_time",
  articleExpirationTime: "article:expiration_time",
} as const;

type NameFields = keyof Omit<MetaFields, PropertyKeys>;

type PropertyFields = typeof transformedTagNames[PropertyKeys];

type Name = {
  name: NameFields;
  content: string;
};

type Property = {
  property: PropertyFields;
  content: string;
};

// transform meta tag names to hyphen case or whatever the spec is, filter falsy values
export const metaTags = (meta: MetaFields, metaOptions?: MetaOptions): (Name | Property)[] => {
  const metaProperties = Object.entries(meta).reduce((acc, [key, value]) => {
    if (!meta[key as keyof MetaFields] || !value) {
      return acc;
    }
    const transformedTagName = transformedTagNames[key as PropertyKeys];
    if (transformedTagName?.startsWith("og:") || transformedTagName?.startsWith("article:")) {
      if (Array.isArray(value)) {
        return acc.concat(
          value.map((v) => ({
            property: transformedTagName,
            content: v,
          }))
        );
      }
      return [
        ...acc,
        {
          property: transformedTagName,
          content: transformedTagName === "og:description" ? truncate(value as string, 155) : value,
        } as Property,
      ];
    }

    return [
      ...acc,
      {
        name: key,
        content: key === "description" ? truncate(value as string, 155) : value,
      } as Name,
    ];
  }, [] as (Name | Property)[]);

  return metaOptions ? applyOptions(metaProperties, metaOptions) : metaProperties;
};

export const applyRobotOptions = (
  content: string,
  { hiddenFromSearchEngine, excludeLinksFromSearchRankings }: MetaOptions
) => {
  let values =
    content
      ?.split(",")
      .map((value) => {
        if (value === "all") {
          return ["index", "follow"];
        }
        if (value === "none") {
          return ["noindex", "nofollow"];
        }
        return value;
      })
      .flat() || [];

  if (hiddenFromSearchEngine) {
    values = values.filter((value) => value !== "index" && value !== "noindex").concat("noindex");
  }

  if (excludeLinksFromSearchRankings) {
    values = values
      .filter((value) => value !== "follow" && value !== "nofollow")
      .concat("nofollow");
  }

  return values.join(",").replace(/^,/, "");
};

const truncate = (str: string, length: number, endWith = "..."): string => {
  if (str.length <= length + endWith.length) return str;
  return str.slice(0, length - endWith.length) + endWith;
};

const applyOptions = (
  properties: (Name | Property)[],
  { hiddenFromSearchEngine, excludeLinksFromSearchRankings }: MetaOptions
) => {
  const robots = properties.find((property) => "name" in property && property.name === "robots");

  if (robots) {
    return properties.map((property) => {
      if ("name" in property && property.name === "robots") {
        return {
          ...property,
          content: applyRobotOptions(property.content, {
            hiddenFromSearchEngine,
            excludeLinksFromSearchRankings,
          }),
        };
      }

      return property;
    });
  }

  return properties.concat({
    name: "robots",
    content: applyRobotOptions("", {
      hiddenFromSearchEngine,
      excludeLinksFromSearchRankings,
    }),
  });
};
