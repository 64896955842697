import { Organization, WithContext } from "schema-dts";
import { SchemaOutput } from "../types";

type BaseOrganization = WithContext<Organization>;
export type TeliaOrganization = Pick<
  BaseOrganization,
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  | "@context"
  | "@type"
  | "name"
  | "alternateName"
  | "description"
  | "url"
  | "logo"
  | "address"
  | "sameAs"
>;

const orgSchemaBlob = (): TeliaOrganization => {
  return {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Telia",
    alternateName: "telia.se",
    description: "Mobilt, bredband, tv och streaming",
    url: "https://www.telia.se/",
    logo: "https://www.telia.se/privat/om/telia-logo",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Stjärntorget 1",
      addressLocality: "Solna",
      addressCountry: "SE",
      postalCode: "169 79",
    },
    sameAs: [
      "https://www.youtube.com/teliasverige",
      "https://twitter.com/TeliaSverige",
      "https://www.instagram.com/teliasverige/",
      "https://se.linkedin.com/company/teliacompany",
      "https://www.facebook.com/telia",
    ],
  };
};

export const organizationMeta = (): SchemaOutput => {
  const blob = orgSchemaBlob();
  return {
    json: () => JSON.stringify(blob),
  };
};
